/** @format */

:root {
  --primary-color: #c01f25;
  --second-color: #ffffff;
  --third-color: #171717;
  --color: #1e1e1e;
}

/*Dashboard CSS*/
.addash {
  padding: 1rem;
  min-height: 90vh;
}
.user_profile img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.pointer_d {
  cursor: not-allowed;
}

.dash_card {
  border: 1px solid var(--primary-color);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 1px 2px 12px 0px rgb(170, 31, 37, 0.7);
}

.dash_card-end {
  background-color: #ffe140;
  color: var(--primary-color);
}

.dash-card-body img {
  width: 50px;
  height: 50px;
}

.dash_card h5 {
  font-size: 1.5rem;
  font-weight: 600;
}

.dash_card span p {
  font-size: 1.3rem;
  font-weight: 600;
}

/*Pagination CSS*/
.pagination-css-pre {
  background: var(--third-color);
  padding: 4px 10px;
  display: flex;
  align-items: center;
  border: 1px solid var(--primary-color);
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin: 0 12px 0 0;
  cursor: pointer;
  box-shadow: inset 0 5px 10px rgb(170, 31, 37, 0.1),
    0 2px 5px rgb(170, 31, 37, 0.5);
  font-size: 14px;
}
.pagination-css-next {
  background: var(--third-color);
  padding: 4px 10px;
  display: flex;
  align-items: center;
  border: 1px solid var(--primary-color);
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  margin: 0 12px 0 0;
  cursor: pointer;
  box-shadow: inset 0 5px 10px rgb(170, 31, 37, 0.1),
    0 2px 5px rgb(170, 31, 37, 0.5);
  font-size: 14px;
}

.pagination-css {
  margin-left: 0px;
  color: var(--second-color);
  padding: 4px 10px;
  margin: 0 12px 0 0;
  border: 1px solid var(--primary-color);
  background: var(--third-color);
  cursor: pointer;
  box-shadow: inset 0 5px 10px rgb(170, 31, 37, 0.1),
    0 2px 5px rgb(170, 31, 37, 0.5);
  font-size: 14px;
}
.pagination-css-active {
  margin-left: 0px;
  padding: 4px 10px;
  margin: 0 12px 0 0;
  border: 1px solid var(--primary-color);
  background: var(--third-color);
  cursor: pointer;
  box-shadow: inset 0 5px 10px rgb(170, 31, 37, 0.1),
    0 2px 5px rgb(170, 31, 37, 0.5);
  font-size: 14px;
  color: var(--primary-color); /* Change this to any color you want */
}
.pagination-css-pre:hover,
.pagination-css-next:hover,
.pagination-css:hover {
  background-color: transparent;
  color: var(--primary-color);
  padding: 4px 10px;
  margin: 0 12px 0 0;
  border-bottom: 1px solid var(--primary-color);
}

.pagination-css:focus {
  outline: none;
  background-color: transparent;
  /*color:#707070;*/
}

/*Add Profile CSS*/

.addprofile {
  border: 1px solid var(--primary-color);
  border-radius: 0.5rem;
  box-shadow: 1px 2px 12px 0px rgb(170, 31, 37, 0.7);
  padding: 1rem;
  background-color: var(--third-color);
}

.pic-holder {
  position: relative;
  display: inline-block;
  text-align: center;
  position: relative;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.upload-file-block input[type="file"] {
  display: none;
}

.pic-holder .pic {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.pic-holder .upload-file-block,
.pic-holder .upload-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(90, 92, 105, 0.7);
  color: #f8f9fc;
  font-size: 12px;
  font-weight: 600;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.pic-holder .upload-file-block {
  cursor: pointer;
}

.pic-holder:hover .upload-file-block,
.uploadProfileInput:focus ~ .upload-file-block {
  opacity: 1;
}

.input-label label {
  color: var(--primary-color);
  margin: 0;
  font-size: 0.9rem;
}

.input-pic-border {
  border: 1px solid var(--primary-color);
  border-radius: 0.5rem;
  padding: 1rem;
}

.input-label h5 {
  color: var(--primary-color);
  margin: 0;
}

.input-pic-border-pic {
  border: 1px dotted var(--primary-color);
  padding: 3rem;
  width: 50%;
  margin: auto;
}

.input-images {
  cursor: pointer;
}

.upload-btn {
  background-color: var(--primary-color);
  border-radius: 0.5rem;
  padding: 0.6rem 1rem;
  color: var(--second-color);
}

.upload-btn:hover,
.upload-btn:active {
  background-color: var(--third-color);
  border-radius: 0.5rem;
  border: 1px solid var(--primary-color);
  padding: 0.6rem 1rem;
}

.sutbtn > span:nth-child(2) {
  float: right;
}

.img_h_center_container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.img_v_center {
  position: relative;
  padding: 1rem;
}
.img_v_center img {
  border: 1px solid var(--primary-color);
  border-radius: 0.5rem;
  margin: 0 0.5rem;
  width: 180px;
  height: 180px;
  object-fit: contain;
}
.img_v_center i {
  position: absolute;
  right: 30px;
  padding: 5px 7px;
  border-radius: 50%;
  top: 20px;
  background: #00000078;
  cursor: pointer;
  color: var(--second-color);
}
.img_v_center video {
  border: 1px solid var(--primary-color);
  border-radius: 0.5rem;
  margin: 0 0.5rem; 
}

/*View Profile CSS*/
.headerimage-bg {
  padding: 9rem 6rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position: 50% -1px;
  border: 1px solid var(--primary-color);
  border-radius: 0.5rem;
}

.headerimage-input {
  position: absolute;
  left: 2rem;
  top: 1rem;
}

.headerimage-heading {
  font-weight: 600;
  font-size: 3rem;
}

.headerimage-text {
  font-size: 1.6rem;
  font-weight: 500;
}

.overlap-section-inner {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -6rem;
}

.model-round-img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 100%;
  border: 5px solid var(--second-color);
}

.viewcardtop {
  margin-top: 8rem;
}

.viewcardtop1 {
  margin-top: 1.5rem;
}

.viewcard {
  border: 1px solid var(--primary-color);
  padding: 1rem;
  background-color: var(--third-color);
  border-radius: 0.5rem;
}

.basic-detail .basic-detail-border {
  border-top: 1px solid var(--second-color);
  margin-top: 1rem;
  width: 98%;
  margin: 0.6rem auto 0 auto;
}

.infoedit {
  float: left;
  font-size: 1.2rem;
}

.infoedit1 {
  float: right;
}

.basicinfo {
  float: left;
  color: var(--primary-color);
  padding-right: 10px;
  font-size: 14px;
  margin-bottom: 0.3rem;
}

.basicdata {
  word-wrap: break-word;
  font-size: 14px;
  margin: 2px 0;
  text-align: right;
}

.view-profile-img img {
  width: 100%;
  height: 280px;
  object-fit: contain;
  border: 1px solid var(--primary-color);
  border-radius: 0.5rem;
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 600px;
}

.video-container video {
  border-radius: 0.5rem;
  border: 1px solid var(--primary-color);
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-button img {
  width: 50px;
  height: 50px;
  border: none;
}

/*Create Ads CSS*/
.add-row-duration {
  margin-left: 1rem;
}

.add-row-btn {
  border: 1px solid var(--primary-color);
  padding: 0.3rem 0.5rem;
  position: absolute;
  bottom: 0.1rem;
  border-radius: 0.2rem;
  cursor: pointer;
}

/* show modal css in active ads */
.modal_container_availability {
  border-radius: 0.45rem;
  position: fixed;
  background-color: var(--third-color);
  /* left: 48.7vw; */
  left: 48vw;
  z-index: 9;
  transform: translate(-50%, -50%);
  border: 1px solid var(--primary-color);
  box-shadow: 1px 2px 12px 0px rgba(170, 31, 37, 0.7);
  animation: topToBottom 2s forwards;
  scroll-behavior: smooth;
  padding: 1rem 1.4rem;
}

.modal_container_availability_directShow {
  border-radius: 0.45rem;
  background-color: var(--third-color);
  border: 1px solid var(--primary-color);
  box-shadow: 1px 2px 12px 0px rgba(170, 31, 37, 0.7);
  padding: 0rem 1.4rem 0.1rem 1.4rem;
}


.modal_container {
  position: fixed;
  left: 50vw;
  z-index: 9;
  transform: translate(-50%, -50%);
  box-shadow: 1px 2px 12px 0px rgba(170, 31, 37, 0.7);
  animation: topToBottom 2s forwards;
}

@keyframes topToBottom {
  0% {
    top: -54vh;
  }
  50% {
    top: 50%;
  }
  100% {
    top: 50%;
  }
}

/*Plan CSS*/
.plan {
  border: 1px solid var(--primary-color);
  padding: 1rem;
  border-radius: 0.45rem;
}

.plan img {
  width: 60px;
}

.plan .plan-text {
  color: var(--primary-color);
  font-size: 1.2rem;
}

.plan .plan-text .plan-detail {
  color: var(--second-color);
}

.plan .plan-text p a {
  color: #dc0007;
}

.single_price_plan {
  position: relative;
  border-radius: 0.5rem;
  background-color: var(--third-color);
  transition: transform 0.5s;
  transition: all 200ms ease-in;
  min-height: 370px;
}

.button-plan {
  border-top: 1px solid var(--primary-color);
  padding: 1rem 0rem;
  display: flex;
  justify-content: center;
}

.single_price_header {
  background-color: var(--primary-color);
  padding: 0.8rem;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.single_price_header-img {
  border: 1px solid var(--second-color);
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.single_price_header-img img {
  width: 40px;
  height: 40px;
  -webkit-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  transition: all 1000ms ease;
}
.single_price_plan:hover .single_price_header-img img {
  transform: rotate(360deg);
}

.single_price_plan .title {
  text-transform: capitalize;
  z-index: 1;
}

.single_price_plan .title h3 {
  font-size: 1.5rem;
  margin: 0;
}

.single_price_plan .price {
  text-align: center;
  padding: 1rem 0;
}

.single_price_plan .price span {
  color: var(--primary-color);
}

.single_price_plan .title p {
  font-weight: 300;
  line-height: 1;
  font-size: 14px;
}

.single_price_plan .title .line {
  width: 80px;
  height: 4px;
  border-radius: 10px;
  background-color: var(--primary-color);
}

.single_price_plan .price h4 {
  font-size: 2.4rem;
  line-height: 1;
  margin-bottom: 0;
  display: inline-block;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: transparent;
  background-image: linear-gradient(90deg, #fff, #c01f25);
}

.single_price_plan .plan-description p {
  line-height: 16px;
  margin: 0;
  padding: 10px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed rgb(221, 221, 221, 0.2);
}

.single_price_plan.active,
.single_price_plan:hover,
.single_price_plan:focus {
  box-shadow: 0 6px 50px 8px rgb(170, 31, 37, 0.15);
  margin-top: 0.25rem;
  transform: scale(1.1);
  transition: all 200ms ease-in;
}

.section-heading h3 {
  margin-bottom: 1rem;
  font-size: 3.125rem;
  letter-spacing: -1px;
}

.single_price_plan .plan-description p i {
  color: #2ecc71;
  margin-right: 0.6rem;
}

.single_price_plan .plan-description p .lni-close {
  color: var(--primary-color);
  margin-right: 0.6rem;
}

.single_price_plan .price .line {
  width: 150px;
  margin: auto;
  height: 4px;
  border-radius: 10px;
  background-color: var(--primary-color);
}

.single_price_plan .plan-description {
  padding: 1rem 2rem;
}

.single_price_plan .button-plan {
  border-top: 1px solid var(--primary-color);
  padding: 1rem 0rem;
  display: flex;
  justify-content: center;
}

/*My Account*/

.viewcard-img {
  border: 1px solid var(--primary-color);
  padding: 1rem;
  background-color: var(--third-color);
  border-radius: 0.5rem;
}

.pic-holder-account {
  position: relative;
  display: inline-block;
  text-align: center;
  position: relative;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.pic-holder-account img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.pic-holder-account .upload-file-block,
.pic-holder-account .upload-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(90, 92, 105, 0.7);
  color: #f8f9fc;
  font-size: 12px;
  font-weight: 600;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.pic-holder-account .upload-file-block {
  cursor: pointer;
}

.pic-holder-account:hover .upload-file-block,
.uploadProfileInput:focus ~ .upload-file-block {
  opacity: 1;
}

.account-name {
  text-align: center;
}
.account-name h4 {
  color: var(--primary-color);
  margin-bottom: 0;
}

/*Password Page CSS*/
.passwordsec {
  border: 1px solid var(--primary-color);
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: var(--third-color);
}
.passwordPage {
  position: relative;
}

.pwdeye {
  position: absolute;
  cursor: pointer;
  bottom: 6px;
  right: 10px;
}
.pwdeye i {
  color: var(--primary-color);
}

.update-plan-btn{
  background-color: #c01f25 !important;
  color: white !important;
  outline: none !important;
  border: 0 !important;
  width: 100vw !important;
  font-size: 13px !important;
  /* border-radius: 5px !important; */
  border-top: 0.5px solid white !important;
  border-bottom: 0.5px solid white !important;
  padding:  0.3rem 0.8rem !important;
}
/* Calendar.css
.calendar-container {
  font-family: Arial, sans-serif;
  width: 300px;
  margin: 0 auto;
}

.cross_close {
  cursor: pointer;
}

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  background-color: var(--third-color); 
  color: var(--second-color); 
  border-radius: 5px;
  margin-bottom: 10px;
}
.calendar-header span {
  color: var(--primary-color);
}
.arrow-button {
  background: var(--third-color);
  color: var(--primary-color);
  border: none;
  text-align: center;
}

.arrow-button i {
  font-size: 14px;
}

.calendar {
  border-radius: 5px;
  font-size: 0.9rem;
}

.calendar-weekdays {
  display: flex;
  justify-content: space-around;
  padding: 5px;
  background-color: var(--third-color); 
  color: var(--primary-color); 
  border-top-left-radius: 0.45rem;
  border-top-right-radius: 0.45rem;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  padding: 2px;
  background-color: var(--third-color);
  border-bottom-left-radius: 0.45rem;
  border-bottom-right-radius: 0.45rem;
}

.calendar-day {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: white; 
  border-radius: 50%; 
}

.day-number {
  font-size: 14px;
}

.calendar-day .active {
  background-color: transparent; 
}

.calendar-day .inactive {
  background-color: transparent; 
}

.booked {
  background-color: var(--primary-color);
}

.available {
  background-color: var(--primary-color);
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.btn-gap-top{
  margin-top:0.6rem;
}   */
@media (max-width: 991.5px) {
  .ds-card {
    padding: 0.5rem;
  }

  .headerimage-bg {
    padding: 6rem 0rem;
  }

  .headerimage-heading {
    font-size: 2.5rem;
  }

  .headerimage-text {
    font-size: 1.3rem;
  }

  .model-round-img {
    width: 180px;
    height: 180px;
  }
}

@media (max-width: 767.5px) {
  .input-pic-border-pic {
    border: 1px dotted var(--primary-color);
    padding: 1rem 0.5rem;
    width: 90%;
    margin: auto;
  }

  .sutbtn  span:nth-child(2) {
    float: unset;
    margin-top: 1rem;
  }
  .sutbtn .m-btn button{
margin-top: 1rem;
  }

  .viewpro-img div > div {
    display: flex;
    justify-content: center;
  }

  .viewpro-img div > div img {
    margin: 1rem auto;
  }
  .add-row-width {
    width: 90%;
  }
  .add-row-btn {
    right: 12px;
  }
  .btn-gap-top{
    margin-top:0rem;
  }
.btn-gap-center{ 
  justify-content: center;
}  
.ads-s-phone{
  margin-top: 0.5rem;
}
}

@media (max-width: 468.5px) {
  .headerimage-heading {
    font-size: 2rem;
  }

  .headerimage-text {
    font-size: 1rem;
  }

  .model-round-img {
    width: 130px;
    height: 130px;
  }

  .overlap-section-inner {
    bottom: -3rem;
  }

  .viewcardtop {
    top: 5rem;
  }

  .viewcardtop1 {
    top: 6rem;
  }
  .add-row-width {
    width: 85%;
  }
  .availability-container{
    max-width: 340px;
  }
  .day-label {
    font-weight: 500;
    color: white;
    font-size: 12px;
    margin-bottom: 5px;
  }
  .ads_location{
flex-direction: column;
  }
}


.availability-container {
  background-color: #000000;
  padding: 40px;
  border-radius: 8px;
  max-width: 400px;
  min-width: 315.33px;
  margin: auto;
}

.weekdays {
  display: flex;
  justify-content: space-between;
  
}

.days {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: auto;
  position: relative; /* Ensure positioning context for absolute positioning */
}

.day-label {
  font-weight: 600;
  color: rgb(255, 255, 255);
  font-size: 12.8px;
  margin-bottom: 5px;
}

.availability-icon {
  position: absolute;
  bottom: -20px; /* Adjust as needed */
  font-size: 18px;
  color: #6dd66d; /* Green color for check icon */
}

.container_weekdays {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.weekdays-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  max-width: 600px;
  margin: auto;
}

.day {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%; /* Ensures circular shape */
  background-color: #000000; /* Default background color */
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
}



.day.available {
  background-color: #6dd66d; /* Green background color for available days */
}

.day span {
  display: none; /* Hide 'Available' or 'Unavailable' text */
}

