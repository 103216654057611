/*Ads Location Table*/
.dashboardtable {
  box-shadow: 1px 2px 12px 0px rgb(170, 31, 37, 0.7);
  border: 1px solid var(--primary-color);
  border-radius: 0.5rem;
  background-color: var(--third-color);
}

.table-fixed-first-columns {
  background-color: var(--primary-color) !important;
  border: none;
  color: var(--second-color);
  font-weight: 500;
  border-radius: 0.46rem;
}

.table-fixed-first-columns {
  margin: 0;
}

.table > :not(caption) > * > * {
  background-color: unset !important;
}

.table td,
.table th {
  color: var(--second-color);
  font-weight: 500;
  font-size: 0.9rem;
  text-align: center;
  vertical-align: middle;
  border: none !important;
}

.table-scrollable .table-color tbody tr:nth-child(odd) {
  background-color: var(--third-color);
}

.table-scrollable .table-color tbody tr:nth-child(even) {
  background-color: var(--color);
  color: var(--second-color);
}

.table-color tbody tr td.table-action i {
  color: var(--primary-color);
}

/* .table-fixed-first-columns thead th:first-child,
.table-fixed-first-columns tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
} */

.table-scrollable {
  overflow-x: auto;
  white-space: nowrap;
  position: relative;
  width: 100%;
  z-index: 0.6;
  margin: auto;
  overflow: auto;
}

.table-scrollable tbody tr td:first-child {
  border-radius: unset;
}
.border-radius-w-pagination tbody tr:last-child td:first-child {
  border-bottom-left-radius: 0.46rem ;
}

.border-radius-w-pagination tbody tr:last-child td:last-child {
  border-bottom-right-radius: 0.46rem  ;
}

.border-radius-n-pagination tbody tr:last-child td:first-child {
  border-bottom-left-radius: 0rem !important;
}

.border-radius-n-pagination tbody tr:last-child td:last-child {
  border-bottom-right-radius: 0rem !important;
}
.table-container-border-radius {
  border-radius: 0 !important;
}

/*Scroll Bar */
.table-scrollable::-webkit-scrollbar {
  width: 1px;
  height: 6px;
}

.table-scrollable::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--primary-color);
  border-radius: 1px;
}

.table-scrollable::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 1px;
}

.table-scrollable::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

@media (max-width: 991.5px) {
  .dashboardtable {
    border-radius: 0.1rem;
  }
  .table-fixed-first-columns {
    border-radius: 0.1rem;
  }
  .border-radius-w-pagination tbody tr:last-child td:first-child{
    border-bottom-left-radius: 0.1rem !important;
  }
  .border-radius-w-pagination tbody tr:last-child td:last-child {
    border-bottom-right-radius: 0.1rem !important;
  }
}
