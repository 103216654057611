/*Admin Dashboard CSS*/

.admin-dash_card {
    border: 1px solid var(--primary-color);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    box-shadow: 1px 2px 12px 0px rgb(170, 31, 37, 0.7);
}

.admin-dash_card-end {
    background-color: #FFE140;
    color: var(--primary-color);
}

.admin-dash-card-body img {
    width: 50px;
    height: 50px;
}

.admin-dash_card h5 {
    font-size: 1.1rem;
    font-weight: 500;
}

.admin-dash_card span p {
    font-size: 1.3rem;
}

/*Ads Sales CSS*/
.ads-Sales-Dropdown{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.ads-Sales-Dropdown .dropdown-fm{
    width: 50%; 
}
/*Report CSS*/
.bar-filter{
 width: 15px; 
 filter: invert(100%);
 margin-right: 0.6rem;
}

.filter-drop{
    padding: 1rem;
    border: 1px solid var(--primary-color);
    border-radius: 0.45rem;
    margin: 0rem 0.1rem;
    display: flex;
    align-items: flex-end;
}
.section-report-drop{
    padding: 1rem;
    border: 1px solid var(--primary-color);
    border-radius: 0.45rem;
    margin: 0rem 0.1rem;
}
.export_menu{
    transform: translate3d(90px, 50px, 0px) !important;
}

.pro-report{
  display: flex;
  justify-content: space-between;
}
.pro-report span a{
    color: var(--primary-color); 
}

/*View Advertiser CSS*/
.plan-a {
    border: 1px solid var(--primary-color);
    padding: 0.5rem;
    border-radius: 0.45rem;
    background-color: var(--black);
}

.plan-a img {
    width: 40px;
}

.plan-a .plan-a-text {
    color: var(--primary-color);
    font-size: 1rem;
}
.plan-a .plan-a-detail {
color: var(--second-color);
}

.img_v_center-view{
    position: relative;
}

.img_v_center-view img{ 
    border: 1px solid var(--primary-color);
    border-radius: 0.5rem;
    margin: 0 0.5rem;
}
.img_v_center-view i{
    position: absolute;
    right: 5px;
    padding: 5px 7px;
    border-radius: 50%;
    top: 5px;
    background: #00000078;
    cursor: pointer;
    color: var(--second-color);
}
/*Media Query CSS*/
@media (min-width:991px) and (max-width:1300px){
    .admin-dash_card {
        padding: 0.5rem 0.9rem;
    }
    .admin-dash_card h5 {
        font-size: 1.1rem;
        font-weight: 500;
    }   
}

@media(max-width:991.5px){
    .ads-Sales-Dropdown{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.5rem 0;
    }
    
    .ads-Sales-Dropdown .dropdown-fm{
        width: 80%; 
    }
}
@media (max-width:767.5px){
    .ads-Sales-Dropdown .dropdown-fm{
        width: 50%; 
    }
}
 