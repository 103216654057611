/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.pointer {
  cursor: pointer;
}

.padding_20 {
  padding: 20px;
  text-align: center;
}

* {
  font-family: "Inter", sans-serif;
  user-select: none;
  font-weight: 500;
}

/* Carrousel */
.header_hero {
  border: 1px solid var(--primary-color);
  border-radius: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 390px;
 height: 400px;
}
.header_hero img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center;
}

.live-ad-card {
  width: 100%;
  padding:1rem 1.5rem;
  border: 1px solid var(--primary-color);
  border-radius: 0.5rem;
  min-height: 400px;
  max-height: fit-content;
}
.live-ad-card h2 {
  color: var(--primary-color);
  text-align: center;
  font-weight: 600;
}
.details1 {
  color: #656565;
}
.details2 {
  color: #656565;
}
.detalist-ad {
  display: flex;
  justify-content: space-between;
}
.detalist-ad p,
.detalist-ad p {
  margin: 0;
}
.detalist-ad-ava h6{
  color: var(--primary-color);
  font-weight: 600;
  margin-bottom: 5px;
}
.detalist-ad-ava-btn, .detalist-ad-ava-btn:hover, .detalist-ad-ava-btn:active.detalist-ad-ava-btn:focus{
border-radius: 0.2rem !important;
padding: 0.5rem 1.5rem !important;
background-color: #000;
}
.carousel-control-prev,
.carousel-control-prev,
.carousel-control-next,
.carousel-control-next {
  color: var(--second-color) !important;
}
.carousel-control-prev, .carousel-control-next{
  width: 8rem !important;
}
.carousel-indicators{
  bottom: -3rem;
  list-style-type: none;
}
.carousel-indicators [data-bs-target]{
  width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid var(--second-color);
  background-color: var(--primary-color) !important;
}
.carousel-indicators .active{
  color: var(--primary-color);
}

 

 /* Container for the chat bot */
.chat-bot-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  text-align: center;
  margin: auto;
}
 
.chat-bot-box {
  height: 70vh;
  overflow-y: auto;
  margin-bottom: 10px;
  padding: 10px;
}

.chat-list {
  position: relative;
  display: flex;
  justify-content: flex-end;
  color: var(--primary-color);
  align-items: center;  
  margin-bottom: 10px;
  text-align: left;
}

.chat-list-message {
  position: relative;
  background-color: #E8EBFA;
  padding:6px 20px 6px 16px;
  border-radius: 4px;
  max-width: 60%;
  width: fit-content;
}

.chat-time {
  position: absolute;
  bottom: -1px;
  right: 0;
  font-size: 12px;
  color: #888;
  margin: 0;
  padding-right: 5px;
}

.chatbot__arrow--right {
  position: absolute;
  right: -6px; 
  transform: translateY(-50%);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #E8EBFA;
  top: 50%; 
}

.user-img-chat {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 1rem;
  object-fit: cover;
}

/* Chat message text */
.chat-message {
  font-size: 0.95rem;
  word-break: break-word; 
}


/*Admin Chat List*/
.admin-chat-msg{
background-color: #f5f5f5;
}
.chat-list-admin{
  position: relative;
  display: flex;
  justify-content: flex-start;
  color: var(--primary-color);
  align-items: center;
  margin-bottom: 10px;
  text-align: left;
}

.admin-img-chat{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 1rem;
  object-fit: cover;
}
.chatbot__arrow--left {
  position: absolute;
  left: -6px; 
  transform: translateY(-50%);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #f5f5f5;
  top: 50%; 
}
.send-msg-btn{
  background: #c01f25;
    padding: 0.7rem;
    margin-right: 0px !important;
    right: 0;
    margin-top: -5px !important;
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    top: 5px;
    cursor: pointer;
}
.snd-msg-inpt{
  padding-right: 3rem;
}
.end-chat-btn{
  background: #c01f25;
  padding: 5px 10px;
  border-radius: 0.2rem;
  font-size: 0.8rem;
  width: 20%;
  margin: auto;
}


.hero-btn-cra-book{
  margin-right: .5rem;
}
@media (max-width:1290px) and (min-width:1200px) {
  .carousel-control-prev, .carousel-control-next{
    width: 4rem !important;
  }
}
@media (min-width:991px){
  .header_hero {
min-width: 100%;
  }
}
@media(max-width:991px){  
.carousel-control-prev, .carousel-control-next{
  width: 2rem !important;
  top: -2rem;
}
.carousel-inner{
  padding: 0 1.5rem;
}
.chat-bot-container {
width: 100%;
}
}
@media(max-width:767px){
.live-ad-card{
  margin-top: 1rem;
}
.carousel-control-prev, .carousel-control-next{
  width: 2rem !important;
  top: -4rem;
}
.header_hero {
  min-width: 100%;  
    }
}

@media(max-width:468px){
  .live-ad-card{
    padding: 1rem 0.5rem;
  }
  .detalist-ad{
    font-size: 0.9rem;
  }
 

}
@media(max-width:370px){
.hero-btn-crausal{
  flex-direction: column;
  gap: 10px;
    }
    .hero-btn-cra-book{
      margin-right:0;
    }
  }