::placeholder {
  color: var(--second-color) !important;
  opacity: 1;
  font-size: 0.9rem;
}

::-ms-input-placeholder {
  color: var(--second-color) !important;
  font-size: 0.9rem;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px var(--primary-color);
  border-radius: 1px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 1px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

input[type="date"] {
  position: relative;
}
/* For WebKit browsers */
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1); /* Invert icon color to white */
  background-color: transparent; /* Set background color to transparent */
  background-position: right;
  background-size: auto;
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 10px;
  width: auto;
}

/* For non-WebKit browsers */
input[type="date"]::-webkit-calendar-picker-indicator {
  background-color: transparent; /* Set background color to transparent */
  color: white; /* Set icon color to white */
}

select option:first-child {
  color: var(--second-color);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.form-layout {
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  margin-top: 50px;
}

.singinform {
  color: var(--second-color);
  text-align: center;
}

.singinform h4 span {
  color: var(--primary-color);
  font-weight: 600;
}
.sendmsg {
  margin-top: 0.2rem;
  /* margin-bottom: 2.8rem; */
}
.sendmsg a {
  color: var(--second-color);
}
.pwdeyelogin i {
  color: var(--primary-color);
}
.pwdeyelogin {
  position: absolute;
  cursor: pointer;
  bottom: 0;
  top: 15px;
  right: 10px;
}
.form-control-ctm {
  color: var(--primary-color) !important;
  background: var(--black) !important;
  border: 1px solid var(--primary-color);
}

.form-select-ctm {
  color: var(--primary-color) !important;
  background: var(--black) !important;
  border: 1px solid var(--primary-color);
  display: block;
  width: 100%;
  padding: 0.375rem 0.6rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; 
}
 

.form-control-ctm:focus,
.form-select-ctm:focus,
.form-check-input:focus {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-bg);
  border-color: var(--primary-color);
  outline: 0;
  box-shadow: 0 0 0 0.25rem #c01f2445;
}
#myMessage-input:focus,
#myMessage-input:focus,
#myMessage-input:focus{
  outline: 0;
  box-shadow: none !important;
}
.form-select-ctm::-webkit-scrollbar {
  width: 8px;
}

.form-select-ctm::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px var(--primary-color);
  border-radius: 1px;
}

.form-select-ctm::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 1px;
}

.form-select-ctm::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

.form-check-input-ctm {
  border-color: var(--primary-color);
  width: 0.8em;
  height: 0.8em;
}
.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.login-register,
.login-register:hover,
.login-register.active {
  color: var(--primary-color);
  margin-left: 0.2rem;
}

.register-btn {
  display: flex;
  justify-content: space-between;
}
.register-btn-disable {
  opacity: 0.5;
}
.underline {
  cursor: pointer;
  color: var(--primary-color);
}
.underline_label {
  text-align: end;
  font-size: 12px;
  font-weight: 400;
  color: var(--primary-color);
}
.get-otp-text {
  color: #ddd;
}

@media (max-width: 767.5px) {
  .register-btn {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .register-btn-m {
    margin-top: 1rem;
  }
}

.rmdp-container {
  width: 100%;
}
.date-picker-input {
  color: var(--primary-color) !important;
  background: var(--black) !important;
  border: 1px solid var(--primary-color) !important;
  display: block;
  width: 100%;
  height: 2.25rem !important;
  padding: 0.375rem 0.6rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  position: relative;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: 2px;
}

.date-picker-input:focus {
  box-shadow: 0 0 0 0.25rem #c01f2445 !important;
  border: 1px solid var(--primary-color) !important;
}
.date-picker-input:focus-visible {
  box-shadow: 0 0 0 0.25rem #c01f2445 !important;
  outline: 1px solid var(--primary-color) !important;
}
.rmdp-panel {
  min-width: 140px !important;
}

.rmdp-border-right {
  border-right: 1px solid var(--primary-color) !important;
}

.rmdp-week-day,
.rmdp-panel-header,
.rmdp-header-values {
  color: var(--primary-color) !important;
  font-weight: 600;
}
.rmdp-arrow {
  border: solid var(--primary-color) !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-day {
  color: var(--second-color) !important;
}
.rmdp-wrapper {
  background-color: var(--third-color) !important;
}
.rmdp-day.rmdp-today span {
  background-color: #7fdbff;
  color: #fff;
  background: #171717 !important;
  border: none !important;
  box-shadow: none !important;
}
.rmdp-day.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--primary-color) !important;
  box-shadow: 0 0 3px #c01f25 !important;
}

.rmdp-panel-body li .b-date,
.rmdp-panel-body li .b-deselect {
  background-color: var(--primary-color) !important;
}

.rmdp-ep-arrow:after {
  background-color: var(--primary-color) !important;
}
.rmdp-ep-arrow[direction="bottom"] {
  border-top: 1px solid var(--primary-color) !important;
}
.rmdp-ep-arrow[direction="top"] {
  border-bottom: 1px solid var(--primary-color) !important;
}
.rmdp-panel-body li {
  box-shadow: none !important;
  background-color: unset !important;
}
.rmdp-shadow,
.rmdp-ep-shadow:after {
  box-shadow: none !important;
  border: 1px solid var(--primary-color) !important;
}

.calender-icon {
  position: absolute;
  right: 1.5rem;
  top: 2rem;
}

/*Input Fields*/
.inputField,
.inputField1,
.inputField2 {
  position: relative;
}

.inputFieldtop {
  margin-top: 0.7rem;
}
.inputField1top {
  margin-top: 1.2rem;
}
.inputField1top input {
margin-top: 2rem; 
}
.inputField2top {
  margin-top: 1.4rem;
}
.inputField input .inputField1 input,
.inputField2 input {
  height: 40px;
}

.inputField input:focus,
.inputField1 input:focus,
.inputField2 input:focus {
  outline: none;
}

.inputField span,
.inputField1 span {
  pointer-events: none;
  position: absolute;
  padding-left: 0.75rem;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: text;
  color: var(--second-color) !important;
  opacity: 1;
  font-size: 0.9rem;
}
.inputField2 span {
  pointer-events: none;
  position: absolute;
  padding-left: 0.75rem;
  left: 0;
  top: 60%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: text;
  color: var(--second-color) !important;
  opacity: 1;
  font-size: 0.9rem;
}

.inputField input:focus + span,
.inputField input:not(:placeholder-shown) + span {
  top: -15px;
  left: 0px;
  transform: scale(0.9) translateY(0%) translateX(0px);
  color: var(--second-color) !important;
  opacity: 1;
  font-size: 0.9rem;
  padding: 0;
}
.inputField1 input:focus + span,
.inputField1 input:not(:placeholder-shown) + span {
  top: -24px;
  left: 0px;
  transform: scale(0.9) translateY(0%) translateX(0px);
  color: var(--second-color) !important;
  opacity: 1;
  font-size: 0.9rem;
  padding: 0;
}

.inputField2 input:focus + span,
.inputField2 input:not(:placeholder-shown) + span {
  top: -14px;
  left: 0px;
  /* transform: scale(0.9) translateY(0%) translateX(0px); */
  color: var(--second-color) !important;
  opacity: 1;
  font-size: 0.9rem;
  padding: 0;
}

.inputField input,
.inputField span,
.inputField1 input,
.inputField1 span,
.inputField2 input,
.inputField2 span,
.inputField2 select,
.inputField2 span {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
.input-top-sign{
  margin-top: 2rem;
}
.eye-icon{
  position: absolute;
  top: 8px;
  right: 12px;
}

.eye-icon i{
color: var(--primary-color);
}

.form-outline {
  position: relative;
}

.form-outline select:focus {
  outline: none;
}

.form-outline label {
  position: absolute;
  top: 12px;
  left: 25px;
  font-size: 14px;
  pointer-events: none;
  transition: all 0.3s;
  padding-right: 1rem;
}

.form-outline select:focus + label,
.form-outline select:valid + label {
  top: -22px;
  left: 0px;
  font-size: 14px;
  font-weight: 400;
  padding: 0 5px;
  transition: 0.2s ease-in-out;
}
.form-outline label.active {
  top: -22px;
  left: 0px;
  font-size: 14px;
  font-weight: 400;
  padding: 0 5px;
  transition: 0.2s ease-in-out;
}

/*Payment Page CSS*/
#payment-page-signup {
  padding: 50px 0px;
}

.s-payment-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.s-payment-op1 {
  border: 1px solid var(--primary-color);
  width: 100%;
  min-width: 170px;
  padding: 16px 10px;
}

.s-single_price_header .price {
  display: flex;
  align-items: center;
  color: var(--primary-color);
  flex-direction: column;
}
.s-single_price_header .price h4 {
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0;
  display: inline-block;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: transparent;
  background-image: linear-gradient(90deg, #fff, #c01f25);
}
.s-single_price_header .price .line {
  width: 96%;
  margin: auto;
  height: 4px;
  border-radius: 10px;
  background-color: var(--primary-color);
}

.payment-type .types {
  position: relative;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  transition: all 0.5s ease;
}
.payment-type .types .text h4 {
  font-size: 1.5rem;
  color: var(--primary-color) !important;
}
.s-payment-op1.active {
  background-color: var(--primary-color) !important;
  border-bottom: 1px solid var(--primary-color) !important;
  font-size: unset !important;
}
.s-payment-op1.active .payment-type .types .text h4,
.s-payment-op1.active .s-single_price_header .price {
  color: var(--second-color) !important;
}
.s-payment-op1.active .s-single_price_header .price h4,
.s-payment-op1.active .s-single_price_header .price .line {
  background-color: #fff;
  background-image: none !important;
}
.s-payment-op1.active::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  height: 30px;
  width: 30px;
  top: -10px;
  right: -10px;
  color: #fff;
  background: #c01f25;
  border: 2px solid #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.s-payment-info-section {
  margin: auto;
}

.s-payment-info-section .s-payment-info-title h4 {
  color: var(--primary-color);
  font-size: 1.3rem;
}
.s-payment-info-section .plan-description p {
  line-height: 16px;
  margin: 0;
  padding: 10px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed rgb(221, 221, 221, 0.4);
}
.s-payment-info-section .plan-description i {
  color: #2ecc71;
  margin-right: 0.6rem;
}
.s-payment-info-section .plan-description p .lni-close {
  color: var(--primary-color);
}

.coupon-code {
  width: 100%;
  max-width: 300px;
  text-align: center;
}
.promo-box {
  display: flex;
  gap: 10px;
  align-items: center;
}
.promotion_code p {
  cursor: pointer;
  color: var(--primary-color);
}

.promo-box i {
  color: #2ecc71;
}

.biling-detail-bd {
  border: 1px solid var(--primary-color);
  padding: 20px;
  border-radius: 4px;
}
.billing-detail h4 span {
  color: var(--primary-color);
}
.billing-detail-list {
  line-height: 16px;
  margin: 0;
  padding: 10px 0;
  border-bottom: 1px dashed rgb(221, 221, 221, 0.4);
}
.biling-amount {
  color: var(--primary-color);
}
.billing-detail-row {
  margin-top: -60px;
}
@media (max-width: 1200.5px) {
  .payment-type .types .text h4 {
    font-size: 1rem;
  }
  .s-payment-op1 {
    width: 96%;
  }
  .s-payment-section {
    justify-content: space-evenly;
    gap: 15px;
  }
  .billing-detail-row {
    margin-top: 0px;
  }
}

@media (max-width: 767.5px) {
  .plan-info-row {
    gap: 10px;
  }
  .s-payment-section {
    justify-content: center;
    gap: 20px;
  }
}


/* payment-loader */

/* HTML: <div class="loader"></div> */

.payment-verification-container{
    top: 34vh;
    width: 60vw;
    margin: auto;
    position: relative;
    text-align: center;
}

.payment-verification-container p{
  margin-top: 20px;
}

.payment-loader {
  margin: auto;
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: var(--primary-color);
  animation: l15 1s infinite linear;
}
.loader::before,
.loader::after {    
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: l15 2s infinite;
}
.loader::after {
  margin: 8px;
  animation-duration: 3s;
}
@keyframes l15{ 
  100%{transform: rotate(1turn)}
}