:root {
  --primary-color: #c01f25;
  --second-color: #ffffff;
  --third-color: #171717;
  --color: #1e1e1e;
}
/*Sidebar Css*/
.wrapper {
  position: relative;
}

#sidebar-wrapper {
  min-height: 100vh;
  /* margin-left: -15rem;  */
  transition: all 0.5s ease !important;
  width: 20%;
  overflow: auto;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 60;
}

.page-content-wrapper {
  min-width: 80%;
  height: 100vh;
  overflow-x: hidden;
  background-color: var(--color);
}

.sb-sidenav-toggled .page-content-wrapper {
  width: 95%;
  margin: auto;
  margin-right: 0;
}

.menudropdown {
  display: flex;
  align-items: center;
}

#sidebar-wrapper .sidebar-heading {
  padding: 1rem 1.25rem;
  font-size: 1.2rem;
  text-align: center;
  height: 80px;
}
.sub-menu-text {
  font-size: 0.9rem;
}
#sidebar-wrapper .list-group {
  /* width: 15rem; */
  padding: 10px 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 80px);
}

#sidebar-wrapper ul li span.iconmenu i {
  right: 20px;
}

/*Header Navigation CSS*/
#header {
  background-color: var(--third-color);
}

.profile_user img {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
}

.togglebar {
  font-size: 1.3rem;
  cursor: pointer;
}

.fglass {
  color: var(--primary-color);
  top: 4px !important;
}

.pro .dropdown-icon::after {
  -webkit-text-fill-color: var(--second-color);
  align-content: center;
  align-items: center;
  border: none;
  color: var(--second-color);
  content: "\f107";
  display: flex;
  flex-wrap: wrap;
  font-family: "FontAwesome";
  font-size: 14px;
  font-weight: 900;
  position: absolute;
  right: -15px;
}

/* .pro .dropdown-icon .arrow-down{
    border: solid #fff;
    border-width: 0 3px 3px 0;
    padding: 3px;
    position: absolute;
    font-size: 14px;
    font-weight: 900;
    position: absolute;
    right: -15px;
    top: 14px;
} */

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.dropdwon li a:hover{
  background-color: #c01f25 !important;
  color: #fff !important;
}

.profile_menu.show {
  position: absolute;
  left: -7rem;
  top: 3rem;
  background: var(--third-color);
  border: 1px solid var(--primary-color);
}

.profile_menu li a:hover,
.profile_menu li a:focus,
.profile_menu li a:active {
  background-color: var(--primary-color) !important;
  color: var(--second-color);
}

.profile_menu li a {
  color: var(--second-color);
}

.dropdown-icon:hover {
  border: none !important;
  background-color: none !important;
}

.dash-header {
  height: 80px;
  padding: 1rem;
  position: sticky;
  z-index: 3;
  top: 0;
}
a.nav-link.text-white.d-flex.align-items-center.sidebarmenu.active {
  border-radius: 0.2rem !important;
  color: var(--second-color) !important;
  background: var(--primary-color);
  font-size: 1rem !important;
}
a.nav-link.text-white.d-flex.align-items-center.p-0.sidebarmenu.sidebarmenuitem.active {
  padding: 0.2rem 0.5rem !important;
}

/*Side bar CSS*/
.dashboard-logo img {
  width: 50%;
}
.sidebar {
  background-color: var(--third-color);
}

.sidebar ul li a {
  font-size: 1rem;
}

/* .sidebar_advertiser li {
  padding: 1rem;
} */

/* changes */
.sidebar_advertiser li {
  padding: 1rem;
  padding-bottom: 0.2rem;
  padding-top: 0.5rem;
}


/* ul.navi.nav-list {
  border: 1px solid #c01f25;
  padding: 0;
  margin-top: 0.5rem;
  border-radius: 5px;
} */
 
.sidebar_advertiser li img {
  height: 20px;
  width: 20px;
}

a.search-dropdown:hover  , .dropdown-item:focus {
  background-color: #c01f25 !important;
  color: white !important;
}

a.search-dropdown-1:hover  , .dropdown-item:focus {
  background-color: transparent !important;
  color: #c01f25 !important;
}

.fa-caret-fa:before {
  color: var(--second-color);
  -webkit-text-fill-color: var(--second-color);
  align-items: center;
  border: none;
  content: "\f107";
  display: flex;
  font-family: "FontAwesome";
  font-size: 14px;
  font-weight: 900;
  align-content: center;
  flex-wrap: wrap;
}
.nav-bell {
  left: -2rem;
  top: 0;
  position: absolute;
}
.nav-bell i {
  color: var(--primary-color);
  cursor: pointer;
}
.nav-flag-spain img {
  width: 21px !important;
  height: 21px !important;
}
.nav-flag {
  display: flex;
  align-items: center;
}

#sidebar-wrapper .list-group::-webkit-scrollbar {
  width: 10px;
}

#sidebar-wrapper .list-group::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px var(--primary-color);
  border-radius: 1px;
}

#sidebar-wrapper .list-group::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 1px;
}

#sidebar-wrapper .list-group::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

/*Footer CSS*/
.fixed-bottom1 {
  left: auto;
  width: 100%;
  margin-top: auto !important;
}

/*Media Qwery*/
@media (min-width: 1199px) {
  .page-content-wrapper {
    min-width: 0;
    width: 80vw;
    margin-left: 19.8vw;
    margin-right: 0;
  }

  #sidebar-wrapper {
    margin-left: 0;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: 0;
    width: 5%;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper ul {
    padding: 0rem !important;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper ul li a {
    padding: 0.5rem;
  }
  body.sb-sidenav-toggled #wrapper #sidebar-wrapper ul .navi li {
    padding-left: 0.5rem;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper ul li span.iconmenu {
    display: none;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper .list-group {
    height: calc(100vh - 80px);
    padding: 0;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper .sidebar-heading {
    padding: 1rem 0.5rem;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper .sidebar-heading img {
    width: 100%;
  }
  body.sb-sidenav-toggled .dashboard-logo-menu {
    width: 15%;
    display: none;
  }
  .dashboard-logo-menu img {
    display: none;
  }
}

@media (min-width: 992.5px) {
  .searchip {
    width: 25rem;
  }
}

@media (min-width: 768.5px) {
  .togglebtn {
    display: none;
  }
}

@media (max-width: 1198.5px) {
  .page-content-wrapper {
    width: 100% !important;
    margin: auto !important;
    margin-right: 0 !important;
  }

  #sidebar-wrapper {
    margin-left: -16rem;
  }

  .sb-sidenav-toggled #sidebar-wrapper {
    margin-left: 0;
    width: 20vw;
  }

  .sb-sidenav-toggled .page-content-wrapper {
    width: 80% !important;
    margin: auto !important;
    margin-right: 0 !important;
  }
  body.sb-sidenav-toggled .dashboard-logo-menu img {
    display: none;
  }
  body.sb-sidenav-toggled .dashboard-logo-menu {
    width: 15%;
    display: none;
  }
  body.sb-sidenav-toggled .dashboard-logo-menu img {
    width: 100%;
  }
  body.sb-sidenav-toggled .nav-bell {
    left: -1.5rem;
  }
  .dashboard-logo-menu {
    width: 15%;
  }
  .dashboard-logo-menu img {
    width: 100%;
  }
}

@media (max-width: 1100.5px) {
  #page-content-wrapper {
    min-width: 0;
  }

  .sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: 0;
    width: 22%;
  }

  .sb-sidenav-toggled .page-content-wrapper {
    width: 78% !important;
    margin: auto !important;
    margin-right: 0 !important;
  }
}

@media (max-width: 991.5px) {
  #page-content-wrapper {
    min-width: 0;
  }

  .sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -1.3rem;
    width: 25%;
  }

  .sb-sidenav-toggled .page-content-wrapper {
    width: 75% !important;
    margin: auto !important;
    margin-right: 0 !important;
  }
  .dash-header {
    padding: 1.5rem;
  }
}

@media (max-width: 850.5px) {
  .page-content-wrapper {
    min-width: 0;
  }

  .sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: 0;
    width: 28%;
  }

  .sb-sidenav-toggled .page-content-wrapper {
    width: 72% !important;
    margin: auto !important;
    margin-right: 0 !important;
  }
}

@media (max-width: 768.5px) {
  .page-content-wrapper {
    min-width: 0;
  }
}
@media(min-width:768px){
  .btn-toggle-sidebar{
    display: none;
      }
}

@media (max-width: 767.5px) {
  .sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: 0;
    width: 100%;
  }

  .sb-sidenav-toggled .page-content-wrapper {
    width: 100% !important;
    margin: auto !important;
    margin-right: 0 !important;
  }

  .togglebtn {
    color: #fff;
  }

  .sidebar_advertiser {
    /* margin: auto; */
  }

  .profile_menu.show {
    left: -7.5rem;
  }

  .nav-flag {
    display: none;
  }
  .sidebar_advertiser li:first-child{
    padding-top: 0;
  }
}
@media (max-width: 568px) {
  .dashboard-logo-menu {
    width: 20%;
  }
  .searchip {
    width: 8rem;
  }
}
@media (max-width: 550.5px) {
  .dashboard-logo-menu {
    width: 20%;
  }
  .searchip {
    width: 7rem;
  }
}
 

@media (max-width: 410.5px) {
  .dashboard-logo-menu {
    width: 20%;
  }
  .searchip {
    width: 6rem;
  }
}

@media (max-width: 468.5px) and (min-width: 200px) {
  #page-content-wrapper {
    min-width: 0;
  }
  .dashboard-logo-menu {
    width: 25%;
  }
  .nav-bell {
    left: -1.1rem;
  }
  .searchbar-nav {
    left: -0.5rem;
  }
}

@media (max-width: 366.5px) {
  .searchip {
    width: 6.5rem;
  }

  .navflagdash {
    width: 23px;
    height: 23px;
  }

  .navflagdash img {
    width: 14px;
    height: 14px;
  }
  .profile_user img {
    width: 32px;
  }
  .dropdown-icon {
    padding-left: 0;
  }
}


/* mblie */
@media (max-width: 766px) {
  .navi.nav-list li:first-child {
    margin-top: 8px;
  }
}
@media (max-width: 470px) {
  .mobile-noti {
    font-size: 7px;
    top: 1px !important;;
    left: 11px !important;
  }
}
