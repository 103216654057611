#btn-back-to-top {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  display: none;
  z-index: 10;
}
.back-to-top {
  background: var(--primary-color) !important;
  color: var(--second-color) !important;
  cursor: pointer;
  border: none !important;
}
.back-to-top:hover,
.back-to-top.active,
.back-to-top:focus {
  box-shadow: 0 0 0 0.25rem rgba(192, 31, 37, 0.2) !important;
  background: var(--primary-color) !important;
  color: var(--second-color) !important;
  cursor: pointer;
  border: none !important;
}
/*Filter Section CSS*/
#filter-background {
  background-color: var(--primary-color);
  margin-top: 4rem;
  padding: 2rem;
}

#gender {
  background-color: var(--second-color);
  color: var(--primary-color);
  border: none;
  padding: 5px;
  padding-left: 10px !important;
  padding-right: 30px !important;
  border-radius: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 25px;
  cursor: pointer;
}

#gender option {
  background-color: var(--second-color);
  color: var(--primary-color);
}

.input-container {
  position: relative;
}

.input-field {
  width: 20rem;
  padding: 5px 35px;
  border: none;
  border-radius: 25px;
  outline: none;
  color: var(--primary-color);
}

.input-container input.input-field::placeholder {
  color: var(--primary-color) !important;
  opacity: 0.8 !important;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.location-icon {
  left: 10px;
  height: 20px;
}

.dropdown-icon {
  right: 10px;
  height: 20px;
  cursor: pointer;
}
.filter-homepage {
  display: flex;
  cursor: pointer;
}
.filter-homepage p {
  text-transform: uppercase;
  text-decoration: underline;
  margin: 0;
  font-weight: 500;
}
.filter-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/*Premium Section CSS*/
.spacing-page {
  /* margin-top: 100px; */
  margin-top: 50px;
  /* margin-top: 2vw; */
}
.premium-text {
  text-align: center;
  width: 60%;
  margin: auto;
  padding-top: 1vw;
}
.premium-text-color {
  color: var(--primary-color);
}
.premium-text h3 {
  margin: 0.2rem;
}
.premium-text p {
  font-size: 0.95rem;
  color: #ddd;
  margin: 0.5rem;
}
.section-infoedit {
  font-size: 1.4rem;
  padding: 0;
}
.section-infoedit span {
  color: var(--primary-color);
}
.section-detail {
  padding: 0 0.6rem;
}
.section-detail .section-detail-border {
  border-top: 1px solid var(--second-color);
  margin-top: 1rem;
  width: 100%;
  margin: 0.4rem auto 0 auto;
}
.adscard-link,
.adscard-link:hover {
  text-decoration: none;
}

.adscard {
  width: 100%;
  border: 1px solid var(--primary-color);
  position: relative;
  box-shadow: 0.05rem 0.1rem 0.3rem -0.03rem rgba(0, 0, 0, 0.45);
  overflow: hidden;
  border-radius: 0.5rem;
  background-color: var(--second-color);
  cursor: pointer;
}
.adscard img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 0.5rem 0.5rem 0 0;
  transition: transform 0.3s ease; 
}
.adscard:hover img{
transform: scale(1.039); 
}
.ads-photo {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
}
.ads-gallery {
  color: var(--second-color);
  background-color: #000;
  padding: 0px 6px;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  margin: 0rem 0.2rem;
  font-size: 0.8rem;
}
.ads-gallery i { 
  margin-right: 8px;
}
.ads-card-body-footer {
  padding: 0.5rem;
}
.ads-card-body-footer h5 {
  color: var(--black);
  font-weight: 600;
}
.ads-card-body-footer h5 span {
  color: var(--primary-color);
  margin-right: 1rem;
}
.adss-footer img {
  height: 18px;
  width: 18px;
}
.adss-footer {
  color: var(--third-color);
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
}
.adss-footer i {
  color: var(--primary-color);
}
.adss-footer span {
  margin-left: 0.6rem;
}

/*About Service CSS*/
.service-detail-h {
  font-size: 2rem;
}
.service-detail p {
  font-size: 0.9rem;
}
.service-detail span {
  color: var(--primary-color);
}

/*BLog CSS*/
.spacing-blog-top {
  margin-top: 70px;
}
.blog-img {
  border: 1px solid var(--primary-color);
  border-radius: 0.5rem;
  position: relative;
}
.blog-img img {
  border-radius: 0.45rem;
  opacity: 0.4;
}
.blog-img-text {
  position: absolute;
  left: 2rem;
  bottom: 1rem;
}
.blog-img-text h5 {
  color: var(--second-color);
  font-size: 1.5rem;
  margin: 0;
}

.author-name,
.blog-date {
  color: var(--primary-color);
  margin: 0 0.4rem;
  font-size: 0.9rem;
}

.blog-dot {
  height: 5px;
  width: 5px;
  background-color: var(--second-color);
  border-radius: 50%;
}
.blog-body-text {
  margin: 0;
  font-size: 0.9rem;
}
.blog3 {
  margin-top: 0.8rem;
}
.blog-body-text {
  overflow: hidden;
  display: -webkit-box;
  font-size: 0.8rem;
  -webkit-line-clamp: 1;
  width: 90%;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

/*Testimonial CSS*/
.testimonial {
  position: relative;
}
.testimonial-img {
  position: absolute;
}
.testimonial-text {
  position: absolute;
  color: var(--third-color);
}
.testimonial-text p {
  font-size: 0.95rem;
}
.imagebox {
  position: relative;
  display: inline-block;
  background-color: var(--second-color);
  border: 1px solid var(--primary-color);
  border-radius: 20px;
  width: 300px;
  height: 200px;
}

.overlay-text {
  position: absolute;
  top: 10px;
  left: 10px;
  color: var(--third-color);
  font-size: 0.9rem;
  padding: 0.8rem;
}

.bottom-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: -20px;
  height: 50px;
  background-color: var(--primary-color);
  clip-path: polygon(100% 5%, 98% 25%, 100% 50%, 0 50%, 0 25%, 0 5%);
}

.bottom-bar .content {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.1rem 1.4rem;
  font-size: 0.9rem;
}

.imagebox::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -2rem;
  transform: rotate(180deg);
  right: 80px;
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 40px solid var(--second-color);
}

.test-img {
  position: absolute;
  bottom: -4.5rem;
  right: 1.5rem;
}
.test-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.testimonial-slider {
  height: 280px;
}

/*Client Guide CSS*/
.clientguide {
  background-color: var(--second-color);
  border: 1px solid var(--primary-color);
  border-radius: 0.5rem;
  padding: 2rem;
}
.clientguide-text {
  display: flex;
  justify-content: space-around;
  align-content: space-around;
  flex-direction: column;
  align-items: center;
}
.clientguide-text i {
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}
.clientguide-text h4 {
  color: var(--primary-color);
  text-align: center;
  font-size: 1.5rem;
}
.clientguide-text p {
  color: var(--third-color);
  text-align: center;
}

/*MemberShip CSS*/
.membership-back {
  background-color: var(--primary-color);
  padding: 2rem;
}

/*Top Footer CSS*/
.top-footer ul {
  padding-left: 0.6rem;
}
.top-footer ul li {
  list-style-type: none;
  padding: 0.1rem 0;
  font-size: 0.95rem;
  font-weight: 400;
}

/*About Us Pages*/
.aboutpage {
  padding: 80px 0;
  background: url("../img/aboutbanner.png") no-repeat;
  background-position: top;
  background-size: cover;
}
.separator {
  color: var(--primary-color);
  margin: 0 auto 20px;
  max-width: 240px;
  text-align: center;
  position: relative;
}

.separator::before {
  float: left;
}

.separator:after {
  float: right;
}

.separator:before,
.separator:after {
  display: block;
  width: 40%;
  content: " ";
  margin-top: 10px;
  border: 1px solid var(--primary-color);
}
.about-sec {
  display: flex;
  align-items: center;
}
.about-sec-img {
  text-align: center;
}
.about-sec-img img {
  width: 500px;
}
.about-service {
  padding: 50px 0;
  background: url("../img/about-user.png") center no-repeat;
  position: relative;
}

.about-service1 {
  /* background-color: rgba(20, 20, 20, 0.8); */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.service-sec {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.service-sec img {
  width: 60px;
  height: 60px;
}
.service-sec h5 {
  color: var(--primary-color);
  font-size: 1.5rem;
  color: white;
}

.apply-model {
  padding: 120px 0;
  background: url("../img/model.png") center no-repeat;
  background-size: cover;
  position: relative;
}

.apply-model-text {
  width: 60%;
  margin: auto;
}

.apply-model-text h4 {
  color: var(--primary-color);
}

/*Term And Conditions*/
.term-text {
  color: var(--primary-color);
  font-size: 1.2rem;
  margin: 0;
}

/*FAQS CSS*/
.accordion-item {
  /* border-radius: 0.375rem; */
  border-radius: 8px !important;
  /* border: 1px solid #c01f25; */
}
.accordion-item:first-of-type .accordion-button {
  border-radius: 0.375rem !important;
}
.accordion-button.collapsed {
  color: var(--primary-color);
  /* background-color: var(--second-color); */
  border: none;
  border-radius: 0.375rem !important;
}

.accordion-button:not(.collapsed) {
  color: var(--second-color);
  background-color: var(--primary-color);
  border: none;
  border-radius: 0.375rem !important;
}
.accordion-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(192, 31, 37, 0.7) !important;
}

.accordion-button.collapsed::after {
  background-image: url("../img/downarrow.png");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../img/uparrow.png");
}

/*View Ads Profile*/
.header-image-bg {
  background-image: url("../img/userpro.png");
  padding-top: 12rem;
  background-repeat: no-repeat;
  /* height: 100vh; */
  background-size: cover;
  background-attachment: scroll;
  background-position: 70% -40px;
  border-radius: 0rem;
}

.view-ads-profile {
  position: relative;
  top: -1rem;
  display: flex;
  align-items: center;
}
.model-round-profile {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  border: 2px solid var(--second-color);
}
.model-name {
  font-size: 1.8rem;
  margin: 0;
}
.view-ads-profile span {
  margin-left: 2rem;
}

.view-profile-nav {
  background-color: var(--second-color);
  display: flex;
  height: 50px;
  align-items: flex-end;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: none;
  color: var(--primary-color) !important;
  border-bottom: 2px solid var(--primary-color);
  border-radius: 0 !important;
  font-size: 1.2rem;
}

.profile-nav {
  background: none;
  color: var(--third-color) !important;
  font-size: 1.2rem;
}

.profile-info-nav {
  background-color: var(--second-color);
  border: 1px solid var(--primary-color);
  border-radius: 0.4rem;
  padding: 1rem;
}
.profile-info-tab h4 {
  color: var(--third-color);
}
.profile-info-tab-txt {
  color: var(--primary-color);
}
.profile-info-tab .profile-info-border {
  border-top: 1px solid var(--primary-color);
  width: 98%;
  margin: 0.6rem auto 0 auto;
}
.profile-tab-card {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}
.profile-tab-card-head {
  color: var(--primary-color);
}
.profile-tab-card-txt {
  color: var(--third-color);
}
.profile-availbility-table {
  color: var(--third-color);
}
.profile-availbility-table tr th {
  color: var(--third-color);
}
.availbility-table-row i {
  color: var(--primary-color);
}

.availbility-table-row td {
  color: var(--third-color) !important;
}
.availbility-table-row .active {
  color: var(--second-color) !important;
  background: var(--primary-color) !important;
}
.availble-yes-txt {
  display: flex;
  align-items: center;
}
.availble-yes {
  width: 15px;
  height: 15px;
  background-color: var(--primary-color);
}
.availble-No {
  width: 15px;
  height: 15px;
  border: 1px solid var(--primary-color);
}
.availble-yes-txt p {
  color: var(--third-color);
  margin: 0 1rem;
}

.profile-view-img img{
  height:300px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 0.2rem;
  max-height: 70vh;
  cursor: pointer;
}
.profile-view-img img:hover{
  transform: scale(1.009); 
  box-shadow: 0px 2px 8px 0px rgba(192, 31, 37, 0.4);
}
/*Blog CSS*/
.blogspacing {
  padding-bottom: 24px;
}
.card-container {
  height: 100%;
  background-color: var(--second-color);
  border-radius: 8px;
  margin: auto;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 25px;
  border: 1px solid var(--primary-color);
  color: var(--third-color);
}

.card-container .card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
}
.card-container .card-body h1 {
  font-size: 1.3rem;
  margin: 8px 0;
  line-height: 2rem;
}
.card-container .card-body p {
  color: var(--third-color);
}


/*Free Zone Page*/
 
.free-zone-message h2 {
  color: var(--second-color);
  font-size: 24px;
  margin-bottom: 10px;
}

.free-zone-message p {
  color: var(--second-color);
  font-size: 16px;
}

@media (min-width: 768.5px) {
  .top-footer-mobile-block {
    display: none;
  }
  
}
@media (max-width: 991.5px) {
  .filter-g {
    display: none;
  }
  .filter-f {
    margin: 1rem 0;
  }
  .adss-footer {
    font-size: 0.8rem;
  }
  .ads-card-body-footer h5 {
    font-size: 1.1rem;
  }
  .service-detail-h {
    font-size: 1.6rem;
  }
  .blog-img-text {
    left: 0.7rem;
    bottom: 0.5rem;
  }
  .blog-img-text h5 {
    font-size: 1rem;
  }
  .author-img i,
  .author-name,
  .blog-date {
    font-size: 0.8rem;
  }
  .imagebox {
    width: 300px;
  }
  .overlay-text {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
  .bottom-bar .content {
    font-size: 0.9rem;
  }
  .test3-mobile {
    display: none;
  }
  .testimonial-slider {
    height: 280px;
  }
  .clientguide {
    padding: 2rem 1rem;
  }
  .clientguide-text h4 {
    font-size: 1.2rem;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    font-size: 1rem;
  }
  .profile-nav {
    font-size: 1rem;
  }
  .model-round-profile {
    width: 150px;
    height: 150px;
  }
  .adscard{
min-height: 330px;
height: 100%;
  }
}

@media (max-width: 767.5px) {
  .filter-section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .premium-text {
    width: 100%;
  }
  .premium-text p {
    font-size: 0.9rem;
    color: #ddd;
    padding: 0.5rem 1rem;
  }
  .spacing-page {
    margin-top: 45px;
  }
  .adscard {
    margin: 10px 0px;
  }
  .ads-list {
    padding: 0px 5px !important;
  }
  .ads-lists {
    padding: 0 0.5rem;
  }
  .section-detail {
    padding: 0 0.8rem;
  }
  .service-detail-h {
    font-size: 1.5rem;
  }
  .service-detail {
    margin-top: 2rem;
  }
  .spacing-blog-top {
    margin-top: 75px;
  }
  .blog1,
  .blog2 {
    margin-top: 0.8rem;
  }
  .test-mobile {
    display: flex;
    justify-content: center;
  }
  .test2-mobile,
  .test3-mobile {
    display: none;
  }
  .imagebox {
    width: 300px;
  }
  .clientguide-gap {
    margin-top: 0.8rem;
  }
  .top-footer-mobile {
    display: none;
  }
  .aboutpage {
    /* padding: 20px 0; */
  }
  .apply-model-text {
    width: 100%;
  }
  .apply-model {
    padding: 60px 0;
  }
  .header-image-bg {
    padding-top: 6rem;
  }
  .mobile-margin {
    margin-top: 1rem;
  }
  .booking_button_availability{
    margin-bottom: -50px;
  }
  .adscard {
    min-height: 296px;
    height: 100%;
}
}

@media (max-width: 468.5px) {
  .header-image-bg {
    padding-top: 2rem;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    font-size: 0.8rem;
  }
  .profile-nav {
    font-size: 0.8rem;
  }
  .model-round-profile {
    width: 100px;
    height: 100px;
  }
}
@media (max-width: 350.5px) {
  .imagebox {
    width: 260px;
  }
  .input-field {
    width: 16rem;
  }
}
