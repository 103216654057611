:root {
  --black: #171717;
  --primary-color: #c01f25;
  --second-color: #fff;
}
.pri-btn {
  background: var(--primary-color);
  /* margin-top: 20px; */
  padding: 8px 22px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--second-color);
  border: 1px solid var(--primary-color);
}
.pri-btn:hover,
.pri-btn:active,
.pri-btn:focus {
  padding: 8px 22px;
  /* margin-top: 20px; */
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--black) !important;
  color: var(--second-color) !important;
  border: 1px solid var(--primary-color) !important;
}
.pri-btn a {
  color: var(--second-color);
  text-decoration: none;
}

.pri1-btn {
  background: var(--black);
  padding: 8px 22px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--second-color);
  border: 1px solid var(--primary-color);
}
.pri1-btn:hover,
.pri1-btn:active,
.pri1-btn:focus {
  padding: 8px 22px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--primary-color) !important;
  color: var(--second-color) !important;
  border: 1px solid var(--primary-color) !important;
}
.pri1-btn a {
  color: var(--second-color);
  text-decoration: none;
}

.sub-btn {
  background: var(--primary-color);
  padding: 8px 20px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--second-color);
  border: 1px solid var(--primary-color);
}
.sub-btn:hover,
.sub-btn:active,
.sub-btn:focus {
  background-color: var(--black) !important;
  padding: 8px 20px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--second-color) !important;
  border: 1px solid var(--primary-color) !important;
}
.cus-btn {
  background: var(--primary-color);
  padding: 5px 22px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--second-color);
  border: 1px solid var(--primary-color);
}
.cus-btn:hover,
.cus-btn:active,
.cus-btn:focus {
  padding: 5px 22px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--black) !important;
  color: var(--second-color) !important;
  border: 1px solid var(--primary-color) !important;
}

.sec-btn {
  background: var(--black);
  padding: 5px 22px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--second-color);
  border: 1px solid var(--primary-color);
}
.sec-btn:hover,
.sec-btn:active,
.sec-btn:focus {
  background-color: var(--primary-color) !important;
  padding: 5px 22px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--second-color) !important;
  border: 1px solid var(--primary-color) !important;
}

.custom-btn {
  background: var(--primary-color);
  padding: 3px 22px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--second-color);
  border: 1px solid var(--primary-color);
}

.custom-btn:hover,
.custom-btn:active,
.custom-btn:focus {
  padding: 3px 22px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--black) !important;
  color: var(--second-color) !important;
  border: 1px solid var(--primary-color) !important;
}

.hm-btn {
  background: var(--black);
  padding: 3px 22px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--second-color);
  border: 1px solid var(--black);
}
.hm-btn:hover,
.hm-btn:active,
.hm-btn:focus {
  padding: 3px 22px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--second-color) !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--second-color) !important;
}
 
@media screen  and (max-width:1170px){
  .filter-btn-m{
    padding: 6px;
  }
}

@media (max-width: 767.5px) {
  .cus-btn {
    width: 100%;
    margin: 1rem 0;
  }
  .sec-btn {
    width: 100%;
  }

 
}

@media screen and (max-width:343px){
  .filter-btn-m{
    padding: 3px;
  }
}



@media (max-width: 550.5px) {
 
  .mobilebtn {
   font-size: 10px;
   padding: 4px 13px;
  }
}

@media (max-width: 400px) {
 
  .messagebtn {
   font-size: 15px;
   padding: 4px 13px;
  }
}