  
body {
  background: #000;
  color: var(--second-color);
} 
/*Top Header Bar */
.main-header{
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background: #000;
  width: 100%;
  z-index: 9;
}
#topbar {
  background-color: var(--primary-color);
  padding: 8px;
  height: 40px;
}

.navicon {
  width: 25px;
  height: 25px;
  background: var(--second-color);
  font-size: 1rem !important;
  padding: 5px;
  border-radius: 50%;
  text-align: center;
  color: var(--primary-color);
}

.navflag {
  background: var(--second-color);
  /* padding: 5px; */
  border-radius: 50%;
  display: inline-flex;
  width: 25px;
  text-align: center;
  height: 25px;
  justify-content: center;
  align-items: center;
}

.navflag img {
  width: 18px;
  height: 18px;
}

/*Navigation bar*/
.navbar-brand img {
  width: 80%;
}

.coming_soon {
  justify-content: center;
  flex-direction: column;
  display: flex;
  height: 17vh;
  width: fit-content;
  margin: auto;
}

.advertiser_dummy {
  justify-content: center;
  flex-direction: column;
  display: flex;
  height: auto;
  width: 98%;
  margin: auto;
}

.navbar-nav li.nav-item .nav-link {
  color: var(--second-color);
  font-weight: 600;
  font-size: 1.015rem; 
  padding-left:1rem;
  padding-right: 1rem;
}

.navbar-nav li.nav-item .nav-link:hover,
.navbar-nav li.nav-item .nav-link:active {
  color: var(--primary-color);
}

.navbar-toggler {
  color: var(--second-color);
}

.stickey-top{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #000;
  z-index: 5;
}

/*Toggle color*/
/*Navbar toggle close*/
.navbar-toggler {
  border-color: transparent;
  padding: 0;
  background: none;
  position: relative;
  width: 30px;
  height: 30px;
}

.navbar-toggler span {
  display: block;
  width: 100%;
  height: 3px;
  background: var(--second-color);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: transform 0.3s;
}

.navbar-toggler span:nth-child(1) {
  transform: translateY(-9px);
}

.navbar-toggler span:nth-child(3) {
  transform: translateY(7px);
}

.navbar-toggler[aria-expanded="true"] span:nth-child(1),
.navbar-toggler[aria-expanded="true"] span:nth-child(3) {
  transform: rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] span:nth-child(2) {
  opacity: 0;
}

.navbar-toggler[aria-expanded="true"] span:nth-child(1) {
  transform: translateY(0) rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] span:nth-child(3) {
  transform: translateY(0) rotate(-45deg);
}

.signupsvg {
  fill: var(--seconday-color);
}

/*Primary Footer*/
#footer {
  /* margin-top: 50px; */
}
.footer-bar{
  height: 40px !important;
  background-color: var(--primary-color);
  padding: 8px;
  display: flex;
  align-items: center;
}

#copyright{
font-size: 0.9rem;
}
.footer_border {
  border-top: 1px solid var(--second-color);
  padding-top: 50px;
  padding-bottom: 30px;
}

.footer-logo img {
  width: 50%;
}
.footer-logo p{
font-size: 0.95rem;
margin-top: 0.5rem;
}

.footer-menu h5 {
  color: var(--second-color);
  margin: 0;
  font-size: 1.2rem;
}

.footer-menu h5 span {
  color: var(--primary-color);
}

.footer-menu ul li {
  padding: 6px 0;
  font-size: 0.9rem;
}

.footer-menu ul li::marker {
  list-style-type: disc;
  color: var(--primary-color);
}

.footer-menu .footer-itm-mnu {
  padding: 0.5rem;
}

.footer-itm-mnu a{
  color: var(--second-color);
text-decoration: none;
}

.emailError {
  animation-name: emailerr;
  animation-duration: 59s;
  animation-timing-function: ease-in-out;
}
@keyframes emailerr {
  0% {
    color: #000;
    font-size: 1px;
  }
  0.1% {
    color: var(--primary-color);
    font-size: 16px;
  }
  50% {
    color: var(--primary-color);
  }
  75% {
    color: var(--primary-color);
  }
  100% {
    color: var(--primary-color);
  }
}


@media(max-width:1199.5px){
  .footer-bar{
    height: 60px !important;
    background-color: var(--primary-color);
    padding: 8px;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 992.5px) {
  .logo-class {
    width: 20%;
  }
  .header-nav1 {
    display: none;
  }
}

@media (max-width: 991.5px) {
  .header-nav {
    display: none;
  }
  .header-nav1 {
    display: block;
  }
  .top-res {
    width: 55%;
  }
  .top-res1 {
    width: 45%;
  }
  .navbar-m {
    padding: 0rem 0.5rem;
  }
  .navbar-brand img{
width: 50%;
  }
}
@media(max-width:767.5px){
  .footer_border{
    border:none;
    padding-left:1rem;
    padding-right: 1rem;
    padding-top: 0;
  }
  .footer-menu .footer-itm-mnu{
    padding: 0.5rem 1rem;
  }
  .footer-icon{
display: none;
  }

}

@media (max-width: 468.5px) {
  .navbar-brand img {
    width: 60%;
  }
  .logo-class {
    width: 60%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #06d85f;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}
