.view-price {
  /* border: 1px solid var(--primary-color); */
  border-radius: 0.45rem;
}
.view-price thead th {
  background-color: var(--primary-color);
}
/* 
.view-price thead th:first-child{
  border-top-left-radius: 0.45rem;
}
.view-price thead th:last-child{
  border-top-right-radius: 0.45rem;
}
.view-price tbody tr:last-child td:first-child{
  border-bottom-left-radius: 0.45rem;
}
.view-price tbody tr:last-child td:last-child{
  border-bottom-right-radius: 0.45rem;
} */

.price-table {
  background-color: var(--third-color);
  border: 1px solid var(--primary-color);
}

.price-table h2 {
  color: var(--second-color) !important;
}

.view-price tbody tr:nth-last-child(2) {
  border-radius: 0.5rem;
}
/* show modal css in active ads */
.modal_container {
  position: fixed;
  left: 50vw;
  z-index: 9;
  transform: translate(-50%, 0%);
  box-shadow: 1px 2px 12px 0px rgb(170, 31, 37, 0.7);
  animation: toptocenter 1s forwards;
  width: 35%;
  border-radius: 0.5rem;
}

@keyframes toptocenter {
  0% {
    top: -50vh;
  }
  100% {
    top: 18vh;
  }
}

.modal_container .modal-content {
  border-radius: 0.5rem;
}

.renewad img {
  width: 80px;
  height: 80px;
}

.modal-w {
  font-size: 1rem;
}
.modal-w > .content {
  width: 100%;
  padding: 0;
}
.popup-content {
  border: 1px solid var(--primary-color) !important;
  padding: 0px !important;
  border-radius: 0.5rem;
}
.snackbarpoup {
  background-color: #000;
  color: var(--second-color);
  border-radius: 0.4rem;
}
.snackbarpoup h2 {
  text-align: center;
  color: var(--second-color) !important;
}

.snackbarpoup i {
  color: var(--primary-color);
  cursor: pointer;
}

.filterpoup {
  background-color: #000;
  color: var(--second-color);
  padding: 1rem 2rem;
  border-radius: 0.4rem;
}
.filterpoup h2 {
  text-align: center;
  color: var(--second-color) !important;
  font-size: 1.1rem !important;
}
.filterpoup h6 {
  text-align: left;
  color: var(--second-color) !important;
  margin: 5px 0;
  font-size: 0.8rem;
}
.filterpoup i {
  color: var(--second-color);
  cursor: pointer;
}

.filterpopupop1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.filterpopupop {
  width: 80%;
  margin: auto;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
}

.filterpopupop #gender1 {
  background-color: #000;
  color: var(--primary-color);
  border: 1px solid #000;
  padding: 5px;
  padding-left: 10px !important;
  padding-right: 30px !important;
  cursor: pointer;
  appearance: none;
}
.filterpopupop #gender1:hover,
#gender1:active,
#gender1:focus {
  border: 1px solid #000 !important;
}
.select-filter {
  width: 8rem;
  height: 2rem;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0 10px;
  color: var(--primary-color);
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"); */
  background-size: 12px;
}
.filter-input-popup {
  height: 2rem !important;
}
.select-filter option:first-child {
  color: var(--primary-color) !important;
}

.availty-input {
  color: var(--primary-color) !important;
  background: #000 !important;
  border: 1px solid var(--primary-color);
  font-size: 0.8rem;
  height: 2rem;
  width: 90%;
  margin: auto;
}

.rmdp-panel.right > div:last-child {
  overflow-y: scroll;
  scrollbar-width: thin;
}

.rmdp-panel.right > div:last-child::-webkit-scrollbar {
  width: 7px;
}
.rmdp-panel.right > div:last-child::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px var(--primary-color);
  border-radius: 1px;
}

.rmdp-panel.right > div:last-child::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

/*Loader Spiner CSS*/
/* .spiner-loader{
background: var(--third-color);
position: absolute;
z-index: 10;
top: 50%;
height: 150px;
left: 22%;
right: 4%; 
border-radius: 0.45rem;
border-top: none;
} */
.spiner-loader{
position: fixed;
z-index: 9;
top: 50%;
left: 50%;
}

.spinercontainer {
  background-color: transparent;
  z-index: 9;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinercontainer div.line {
  position: relative;
  background-color: var(--primary-color);
  display: inline-block;
  height: 0px;
  border-radius: 5px;
  width: 10px;
  margin: 10px;
  animation: line linear infinite 0.9s;
}





@keyframes line {
  0% {
    height: 0px;
    margin-top: 60px;
  }
  50% {
    height: 50px;
    margin-top: 10px;
  }
  100% {
    height: 0px;
    margin-top: 60px;
  }
}

.spinercontainer div.d1 {
  animation-delay: 0.15s;
}
.spinercontainer div.d2 {
  animation-delay: 0.3s;
}
.spinercontainer div.d3 {
  animation-delay: 0.45s;
}
.spinercontainer div.d4 {
  animation-delay: 0.6s;
}
.spinercontainer div.d5 {
  animation-delay: 0.75s;
}
.spinercontainer div.d6 {
  animation-delay: 0.9s;
}

.spinercontainer div.caption {
  text-align: center;
  color: var(--primary-color);
}

.spinercontainer div.dot {
  display: inline-block;
  margin: 0px;
  padding: 0px;
  animation: dot 1s infinite linear;
}

.spinercontainer div.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.spinercontainer div.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* toaster css */

.notification {
  position: fixed;
  bottom: 20px;
  right: 75px;
  background-color: var(--primary-color);
  color: white;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transform: translateY(20px);
  z-index: 10;
}

.notification.show {
  opacity: 1;
  transform: translateY(0);
}

.notification.hide {
  opacity: 0;
  transform: translateY(20px);
}

.message {
  font-size: 16px;
  font-weight: 500;
  word-break: break-all;
   word-wrap: break-word;
}
.delete-img-admin {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: var(--primary-color);
  background: #00000078;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*Age Confirmation Popup*/
.age-confrimation-popup{
  width: 75%;
  margin: auto;
  border: 1px solid var(--primary-color);
  box-shadow: 0px 2px 16px 4px var(--primary-color);
  border-radius: 0.45rem;
  background-color: #000;
  z-index: 9999;
  padding: 15px ;
  }
  .age-confrimation-popup p{
font-size: 14px;
  }
  .popup-open {
    overflow: hidden;
  }
  
  .popup-open::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
    z-index: 98;
  }

  @media(max-width:560px){
    .age-confrimation-popup{
      margin-left: 0px;
      width: 90%;
      padding: 15px 6px;
    }
    .age-confrimation-popup p{
      font-size: 12px;
        }
  }
  


  /*Booking Popup*/
  .booking-popup{
    background-color: #000;
    border-radius: 0.45rem;
    padding: 20px;
  }
  .booking-popup-title{
    color: var(--primary-color);
    text-align: center;
  }
@media (min-width: 991.5px) {
  .popup-content {
    width: 40% !important;
  }
}
@media (max-width: 991.5px) {
  .filterpopupop {
    width: 90%;
  }
  .modal_container {
    position: fixed;
    animation: 2s;
    left: 50vw;
    z-index: 9;
    transform: translate(-50%, 0%);
    width: 50%;
    border-radius: 0.5rem;
  }

 
  
}
@media(max-width:767.5px){
  .delete-img-admin {
    top: 1.5rem;
    }

}
@media (max-width: 700.5px) {
  .popup-content {
    width: 90%;
  }
  .filterpoup {
    padding: 1.5rem 0.5rem;
  }
  .select-filter {
    width: 8rem;
  }
  .modal_container {
    position: fixed;
    animation: 2s;
    left: 50vw;
    z-index: 9;
    transform: translate(-50%, 0%);
    width: 80%;
    border-radius: 0.5rem;
  }

  .editavailablity_popup {
    padding: 0;
    margin-top: 2rem;
  }

  .editavailablity_popup .rmdp-wrapper .rmdp-panel {
    min-width: 103px !important;
  }
  .notification.hide {
    opacity: 0;
    transform: translateY(0px);
    z-index: 1;
}
  /* .notification{
    top: 0px;
    right: 0px;
    bottom: unset;
    min-height: 55px;
    padding: 10px;
    border-radius: 0;
  } */ 
  .notification {
    top: 0px;
    right: 0px;
    bottom: unset;
    /* min-height: 55px; */
    padding: 10px;
    border-radius: 5px;
    margin: 1rem;
}

}

@media screen and (max-width:350px){
  .select-filter {
    width: 7rem;
  }
}