/* Global css code */
.Border{
    border: 1px solid #c01f25;
}
.Scroll::-webkit-scrollbar{
    width: 10px;
}
.Scroll::-webkit-scrollbar-track{
    background: transparent;
}
.Scroll::-webkit-scrollbar-thumb{
    background-color: #c01f25;
}


/* Main code */

.side_menu {
    z-index: 0 !important;
}
.side_menu_header {
    /* padding: 1.4rem ; */
    padding: 1.35rem 0rem;
}
.req_sec{
    overflow-y: auto;
}
#chat_box{
    margin: 1rem;
}
.req_sec img{
    width: 18rem;
    margin-top: 4vh;
}
.req_sec h3{
    color: #c01f25;
    text-align: center;
}
.request_card{
    border-radius: 5px;
    width: 14.5rem !important;
}

.request_card button{
    background-color: #c01f25;
}
.side_menu_footer {
    /* padding: 0.438rem 1rem !important; */
    padding: 0.3rem 1rem !important;
}

/* Chat Box Section */

.chat_box_header {
    padding: 0.9rem 1rem !important;
}
.end_chat {
    padding: 0.5rem;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.end_chat:hover {

    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* align-content: center; */
    background-color: #c01f247d;
}
.avtar1{
    padding: 0.5rem;
    width: 40px;
    height: 40px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    /* border-radius: 10px !important; */
}
.avtar2{
    background-color: #c01f25;
    padding: 0.4rem;
    border: 0.5px solid rgba(255, 255, 255, 0.887);
    display: flex !important;
    justify-content: center;
    align-items: center;
    transform: translate(-73%, -135%) !important;
}
.main_chat_box{
    overflow-y: auto;
}
/* .main_chat_box p{
    background-color: #c01f25;
    border-radius: 10px 10px 0 10px;
    width: fit-content !important;
} */

.send_msg_sec label{
    cursor: pointer;
}
.send_msg_sec input{
    background:transparent;
    width: 100%;
    color: white;
    outline: none;
    padding: 0.7rem;
}

.send_msg_sec button{
    background-color: #c01f25;
    color: white;
    outline: none;
}
.send_msg_sec button:hover{
    background-color: transparent;
}

/* Responsive code */

.text-responsive{
    font-size: 0.9rem;
    /* padding: 0.6rem; */
}
.svg-responsive{
    width: 1.2rem;
    height: auto;
}
.button-responsive{
    font-size: 0.8rem;
    padding: 0.3rem 0.5rem;
}

.minimise_sideMenu{
    position: absolute;
    background-color: #c01f25;
    cursor: pointer;
    left: 0;
    top: 47%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: none;
    padding: 0.4rem;
    z-index: 6;
}
.minimise_sideMenu:hover{
    background: transparent;
}

@media(max-width:850px){
    .minimise_sideMenu{
        display: block;
    }
    .side_menu{
        display: none !important;
    }
    .toggle_side_menu{
        position: fixed !important;
        display: block !important;
        width: 44vw !important;
        height: 87.8vh;
        display: flex !important;
        z-index: 999 !important;
        background-color: #1e1e1e;
    }
    .req_sec{
        height: 62.1rem;
        border: 1px solid #c01f25;
    }
    .request_card{
        width: auto !important;
    }
}

@media(max-width:560px){
    .toggle_side_menu{
        width:70vw !important;
    }
    .chat_section{
        height: 99vh !important;
        /* width: 98.5vw !important; */
    }
}