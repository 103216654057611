.carousel-btn {
  position: relative;
}
.carousel-btn-pre {
  background-color: var(--primary-color);
  right: 2rem;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.1rem;
}

.carousel-btn-pre .carousel-control-prev-icon,
.carousel-btn-next .carousel-control-next-icon {
  width: 1rem !important;
  height: 1rem !important;
}
.carousel-btn-next {
  background-color: var(--primary-color);
  right: 0;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.1rem;
}

.premium-carousel-dot li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid var(--second-color);
  background-color: var(--primary-color) !important;
  opacity: 0.5;
}
.premium-carousel-dot .active {
  background-color: var(--primary-color);
}

@media(max-width:991.5px){
  .slider-mobile{
display: none;
  }
}